import {useQuery} from '@apollo/client';
import {Container, Stack} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import {DesktopBanner, MobileBanner} from 'customerPortal/components/Banner';
import {getNavigationItems} from 'customerPortal/constants/navigationItems';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React, {useEffect} from 'react';
import {Redirect, Switch, useHistory, useLocation} from 'react-router-dom';
import {PrivateRoute} from 'shared/components/Route';
import {PRIVATE_ROUTES, PUBLIC_ROUTES} from 'shared/components/Route/routes';
import {FEATURE_IDS} from 'shared/utils/featureFlags';
import {getPathWithoutTrailingSlash} from 'shared/utils/urlUtils';
import BookBrowseScreen from '../BookBrowseScreen/BookBrowseScreen';
import WebReaderScreen from '../WebReaderScreen/WebReaderScreen';
import {QUERY_ACCOUNT_DETAILS} from './AccountDetailsScreen/AccountDetails.query';
import AccountDetailsScreen from './AccountDetailsScreen/AccountDetailsScreen';
import {LAST_PURCHASE_DUE_DATE_QUERY} from './AccountSettings.query';
import styles from './AccountSettingsScreen.styles';
import AccountSubscription from './AccountSubscription/AccountSubscription';
import BookManagementScreen from './BookManagementScreen/BookManagementScreen';
import {DesktopMenu, MobileMenu} from './Menu';
import ProfilesScreen from './ProfilesScreen/ProfilesScreen';
import EditProfile from './ProfilesScreen/EditProfile/EditProfileScreen';
import EditProfileCharacterScreen from './ProfilesScreen/EditProfile/EditProfileScreen.picture-screen';
import RemoveProfileScreen from './ProfilesScreen/EditProfile/EditProfileScreen.remove-profile.screen';
import MaxProfilesScreen from './ProfilesScreen/ProfilesScreen.max-profiles-screen';
import AdjustReadingLevel from './ProfilesScreen/AdjustReadingLevel/AdjustReadingLevel';
import {DesktopFeedbackContact} from 'customerPortal/components/FeedbackContact';
import SelfAdjustReadingLevel from './ProfilesScreen/AdjustReadingLevel/SelfAdjustReadingLevel';
import ReferralLink from 'customerPortal/components/ReferralLink/ReferralLink';
import AccountSettingsService from './AccountSettingsScreen.service';
import {Customer} from '../../../shared/types/customer';

const AccountSettings = ({classes}: WithStyles<typeof styles>) => {
  const location = useLocation();
  const history = useHistory();
  const isMobileView = useMobileLayout();

  const {data: purchaseData} = useQuery<{lastPurchaseDueDate: string}>(
    LAST_PURCHASE_DUE_DATE_QUERY,
    {
      onError: error => console.error('Last purchase due date::', error),
    },
  );

  const {data} = useQuery<{
    customer: Customer;
  }>(QUERY_ACCOUNT_DETAILS, {
    onError: error => console.error('Account details::', error),
  });

  const customer = data?.customer;

  useEffect(() => {
    const {pathname} = location;
    if (isMobileView) {
      return;
    }

    if (getPathWithoutTrailingSlash(pathname) === PRIVATE_ROUTES.ACCOUNT.path) {
      history.replace(PRIVATE_ROUTES.ACCOUNT_DETAILS.path);
    }
  }, [location, history, isMobileView]);

  return (
    <>
      {isMobileView ? <MobileBanner /> : <DesktopBanner />}
      <Container maxWidth="lg" className={classes.main}>
        {!isMobileView && (
          <Stack gap="40px" className={classes.aside}>
            <DesktopMenu
              navigationItems={getNavigationItems(customer?.productType)}
              chipContents={purchaseData?.lastPurchaseDueDate}
            />
            <DesktopFeedbackContact />
            {AccountSettingsService.showReferralLink(customer) &&
              customer?.referralCode && (
                <ReferralLink referralCode={customer?.referralCode} />
              )}
          </Stack>
        )}

        <main className={classes.content}>
          <Switch>
            {/* PRIVATE_ROUTES.ACCOUNT is only accessible on mobile.
             * On desktop, when user arrives at /account,
             * they are redirected to /account/details via the useEffect hook
             * so that the highlighted menu item matches the URL.
             * This is the downside to make the reverse navigation
             * through React Router Link.
             * It is required so that upon login on mobile,
             * the user sees the menu first.
             */}
            <PrivateRoute
              exact
              path={PRIVATE_ROUTES.ACCOUNT.path}
              render={() => (
                <MobileMenu
                  navigationItems={getNavigationItems(customer?.productType)}
                  chipContents={purchaseData?.lastPurchaseDueDate}
                  referralCode={customer?.referralCode}
                  showReferralLink={AccountSettingsService.showReferralLink(
                    customer,
                  )}
                />
              )}
            />
            <PrivateRoute
              path={PRIVATE_ROUTES.ACCOUNT_DETAILS.path}
              render={() => <AccountDetailsScreen />}
            />

            <PrivateRoute
              exact
              path={PRIVATE_ROUTES.EDIT_PROFILE_CHARACTER.path}
              render={() => <EditProfileCharacterScreen />}
            />

            <PrivateRoute
              exact
              featureFlagIds={[FEATURE_IDS.BOOK_MGMT_SCREENS]}
              path={PRIVATE_ROUTES.ACCOUNT_BOOKS.path}
              render={() => <BookManagementScreen />}
            />
            <PrivateRoute
              exact
              featureFlagIds={[FEATURE_IDS.BOOK_MGMT_SCREENS]}
              path={PRIVATE_ROUTES.ACCOUNT_BOOK_BROWSE.path}
              render={() => <BookBrowseScreen />}
            />
            <PrivateRoute
              exact
              featureFlagIds={[FEATURE_IDS.CUSTOMER_PORTAL_SUB]}
              path={PRIVATE_ROUTES.ACCOUNT_SUB.path}
              render={() => <AccountSubscription />}
            />
            <PrivateRoute
              path={PRIVATE_ROUTES.ACCOUNT_READER_PROFILE.path}
              render={() => <WebReaderScreen />}
            />
            <PrivateRoute
              path={PRIVATE_ROUTES.ACCOUNT_READER.path}
              render={() => <WebReaderScreen />}
            />

            <PrivateRoute
              exact
              path={PRIVATE_ROUTES.MAX_PROFILE.path}
              featureFlagIds={[FEATURE_IDS.ADD_PROFILES]}
              render={() => <MaxProfilesScreen />}
            />

            <PrivateRoute
              exact
              path={PRIVATE_ROUTES.ACCOUNT_PROFILES.path}
              featureFlagIds={[FEATURE_IDS.PROFILES_SCREEN]}
              render={() => <ProfilesScreen />}
            />

            <PrivateRoute
              exact
              path={PRIVATE_ROUTES.EDIT_PROFILE.path}
              featureFlagIds={[FEATURE_IDS.PROFILES_SCREEN]}
              render={() => <EditProfile />}
            />

            <PrivateRoute
              exact
              path={PRIVATE_ROUTES.REMOVE_PROFILE.path}
              featureFlagIds={[FEATURE_IDS.REMOVE_PROFILE]}
              render={() => <RemoveProfileScreen />}
            />

            <PrivateRoute
              exact
              path={PRIVATE_ROUTES.ADJUST_READING_LEVEL.path}
              featureFlagIds={[FEATURE_IDS.ADJUST_READING_LEVEL]}
              render={() => <AdjustReadingLevel />}
            />
            <PrivateRoute
              exact
              path={PRIVATE_ROUTES.SELF_ADJUST_READING_LEVEL.path}
              featureFlagIds={[FEATURE_IDS.ALLOW_SELF_SERVICE_LEVEL_ADJUST]}
              render={() => <SelfAdjustReadingLevel />}
            />

            <Redirect
              to={{
                pathname: PUBLIC_ROUTES.NOT_FOUND,
                state: {homepageLink: PRIVATE_ROUTES.ACCOUNT.path},
              }}
            />
          </Switch>
        </main>
      </Container>
    </>
  );
};

export default withStyles(styles)(AccountSettings);
