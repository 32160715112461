import {Grid} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import {isRevenueCat, isStripe} from '../CancellationForm.service';
import {CancellationConfirmedBody} from './CancellationConfirmed.body';
import {CancellationConfirmedRevenueCatActions} from './CancellationConfirmed.revenueCatActions';
import {CancellationConfirmedStripeActions} from './CancellationConfirmed.stripeActions';
import styles from './CancellationConfirmed.styles';

const stripeSubtext =
  'If you wish to re-subscribe in the future, please reach out to support@ello.com';

const revenueCatSubtext =
  'Please click the link below to cancel your subscription in the settings menu. If you wish to re-subscribe in the future, please reach out to support@ello.com';

interface Props extends WithStyles<typeof styles> {
  billingSource?: string;
  hideDesktopBackButton?: boolean;
  onConfirm: () => Promise<void>;
  onBack?: () => void;
}

const CancellationConfirmed = ({
  billingSource,
  classes,
  hideDesktopBackButton = false,
  onConfirm,
  onBack = () => {},
}: Props) => {
  const subtext = isRevenueCat(billingSource || '')
    ? revenueCatSubtext
    : stripeSubtext;

  return (
    <>
      <Grid container className={classes.content}>
        <CancellationConfirmedBody subtext={subtext} />
        {isStripe(billingSource || '') && (
          <CancellationConfirmedStripeActions
            hideDesktopBackButton={hideDesktopBackButton}
            onConfirm={onConfirm}
            onBack={onBack}
          />
        )}
        {isRevenueCat(billingSource || '') && (
          <CancellationConfirmedRevenueCatActions
            hideDesktopBackButton={hideDesktopBackButton}
            onConfirm={onConfirm}
            onBack={onBack}
          />
        )}
      </Grid>
    </>
  );
};

export default withStyles(styles)(CancellationConfirmed);
