import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';

const TrialCompliance = ({
  classes,
  priceString,
  trialPeriodDays,
  rewardCoupon,
}) => {
  const trialEnd = moment().add(trialPeriodDays, 'days');
  const endDate = rewardCoupon ? moment(trialEnd).add(1, 'months') : trialEnd;

  const formattedEndDate = endDate.format('MMM D YYYY');

  return (
    <Box mt={1} mb={1}>
      <Box>
        <Typography
          align="left"
          component="p"
          variant="caption"
          className={classes.subscriptionDetails}>
          {`Cancel any time. You will be charged $${priceString} on ${formattedEndDate} when your ${
            rewardCoupon ? 'first free month ends' : 'trial ends'
          }. Sales tax may apply.`}
        </Typography>
      </Box>
    </Box>
  );
};

export default TrialCompliance;
