import {useLazyQuery, useQuery} from '@apollo/client';
import CancellationForm from 'customerPortal/components/CancellationForm/CancellationForm';
import {RenewalConfirmationDialog} from 'customerPortal/components/Modal/RenewalConfirmationDialog';
import Section from 'customerPortal/components/Section';
import React, {useState} from 'react';
import {ReactComponent as IconAddChild} from 'shared/assets/icons/iconAddChild.svg';
import {ReactComponent as IconBilling} from 'shared/assets/icons/iconBilling.svg';
import {ReactComponent as IconCancel} from 'shared/assets/icons/iconCancel.svg';
import {ReactComponent as RenewSubscriptionIcon} from 'shared/assets/icons/renewSubscription.svg';
import Alert from 'shared/components/common/Alert/Alert';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';
import {appleBillingManagementUrl} from 'shared/utils/appBilling';
import featureFlags, {FEATURE_IDS} from 'shared/utils/featureFlags';
import {
  isDigitalSubscription,
  isUpgradableCustomer,
  supportsDigital,
} from 'shared/utils/productType';
import {sendSlackMessage, SlackMessageTypes} from 'shared/utils/slack';
import {
  isCanceledSubscription,
  isCancellationStatus,
} from 'shared/utils/subscriptionStatus';
import {QUERY_ACCOUNT_DETAILS} from '../AccountDetailsScreen/AccountDetails.query';
import {
  QUERY_CURRENT_SUBSCRIPTION,
  QUERY_STRIPE_PORTAL,
} from './AccountSubscription.query';
import SectionHeader from './AccountSubscription.section-header';
import {Customer, QueryResult} from './AccountSubscription.types';
import {isInactiveSubscription} from './AcountSubscription.service';
import {ActionCard} from './ActionCard';
import {SubscriptionInfo} from './SubscriptionInfo';
import {BillingSource} from '../../../../shared/types/customer';

const AccountSubscription = () => {
  const [showCancellationForm, setShowCancellationForm] = useState(false);
  const [showRenewalConfirmation, setShowRenewalConfirmation] = useState(false);
  const [actionDisabled, setActionDisabled] = useState(false);

  const onError = (error: any) =>
    console.error('Account Subscription::', error);

  const {data, loading, error} = useQuery<{subscription: QueryResult}>(
    QUERY_CURRENT_SUBSCRIPTION,
    {
      onError,
    },
  );

  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useQuery<{
    customer: Customer;
  }>(QUERY_ACCOUNT_DETAILS, {
    onError,
  });

  const profileNames =
    customerData?.customer.profiles.map(profile => profile.name) || [];

  const billingSource =
    customerData?.customer?.billingSource || BillingSource.Stripe;
  const isStripeBillingSource = billingSource === BillingSource.Stripe;
  const isRevenueCatBillingSource = billingSource === BillingSource.ReveneuCat;
  const billingPortal =
    billingSource === BillingSource.ReveneuCat ? 'Apple' : BillingSource.Stripe;

  const closeCancellationForm = () => {
    setShowCancellationForm(false);
  };

  const openCancellationForm = () => {
    window.analytics?.track('Cancellation Started');
    setShowCancellationForm(true);
  };

  const renewSubcription = () => {
    if (isStripeBillingSource) {
      const message = `${customerData?.customer.userDetails.email} would like to resubscribe. Please reach out to them.`;
      sendSlackMessage(SlackMessageTypes.RenewSubscription, message);
      setShowRenewalConfirmation(true);
      setActionDisabled(true);
      return;
    }
    window.location.assign(appleBillingManagementUrl);
  };

  const [
    queryStripePortal,
    {error: stripePortalError, loading: isStripePortalLoading},
  ] = useLazyQuery(QUERY_STRIPE_PORTAL, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      returnUrl: window.location.href,
    },
    onCompleted: data => {
      if (data) {
        window.location.assign(data.createStripePortalSession.url);
      }
    },
    onError: error => console.error('Stripe portal::', error),
  });

  const hasError = error || customerError;
  const isLoading = loading || customerLoading || isStripePortalLoading;
  const subscriptionStatus = isStripeBillingSource
    ? customerData?.customer.subscriptionStatus
    : customerData?.customer.revenueCatSubscriptionStatus;
  const subscriptionPeriodEnd = customerData?.customer.subscriptionPeriodEnd;
  const customerProductType = customerData?.customer.productType;
  const hasInactiveSubscription = isInactiveSubscription(subscriptionPeriodEnd);

  const cancellationSupported =
    isDigitalSubscription(customerProductType) &&
    !isCanceledSubscription(subscriptionStatus) &&
    featureFlags.isOn(FEATURE_IDS.CANCELLATION_MODAL);

  const allowRCCancellation =
    isRevenueCatBillingSource &&
    featureFlags.isOn(FEATURE_IDS.INAPP_CANCELLATION);

  const allowStripeCancellation = isStripeBillingSource;

  const allowCancellation =
    cancellationSupported && (allowRCCancellation || allowStripeCancellation);

  const showRenewalButton =
    supportsDigital(customerProductType) &&
    isCancellationStatus(subscriptionStatus) &&
    featureFlags.isOn(FEATURE_IDS.RENEW_SUBSCRIPTION);

  const showManageBilling = featureFlags.isOn(FEATURE_IDS.SHOW_MANAGE_BILLING);

  return (
    <>
      <Section
        titleSection={
          <SectionHeader
            subscriptionStatus={subscriptionStatus}
            subscriptionPeriodEnd={subscriptionPeriodEnd}
          />
        }
        error={hasError ? 'Unable to load subscription' : ''}
        loading={isLoading}>
        <>
          {data?.subscription && (
            <>
              <SubscriptionInfo
                title={data.subscription.title}
                description={data.subscription.description}
                name={data.subscription.name}
                isInactive={!!hasInactiveSubscription}
                productType={customerProductType}
              />
              {showManageBilling && (
                <ActionCard
                  onClick={() => {
                    if (billingSource === 'Stripe') {
                      queryStripePortal();
                      return;
                    }
                    window.location.assign(appleBillingManagementUrl);
                  }}
                  Icon={IconBilling}
                  title={'Manage Payment & Billing'}
                  subtitle={
                    'View or make changes to your payment method, billing' +
                    `information or subscription on ${billingPortal}`
                  }
                />
              )}
              {isUpgradableCustomer(customerProductType) && (
                <ActionCard
                  route={PRIVATE_ROUTES.ADD_CHILD.path}
                  Icon={IconAddChild}
                  title={'Add Child'}
                  subtitle={
                    <>
                      Add a child to share books with or to sign up for an
                      additional book bundle at a different reading level.
                    </>
                  }
                />
              )}
              {allowCancellation && (
                <ActionCard
                  Icon={IconCancel}
                  title={'Cancel Subscription'}
                  onClick={() => openCancellationForm()}
                  subtitle={
                    <>
                      Cancel your current Ello subscription. Your subscription
                      will remain active until the end of your current period.
                    </>
                  }
                />
              )}

              {showRenewalButton && (
                <ActionCard
                  Icon={RenewSubscriptionIcon}
                  title={'Renew Subscription'}
                  onClick={renewSubcription}
                  actionDisabled={actionDisabled}
                  subtitle={
                    <>
                      Your child’s level and reading progress will be restored,
                      they’ll continue where you left off
                    </>
                  }
                />
              )}
            </>
          )}
        </>
      </Section>
      {stripePortalError && <Alert error={'Unable to connect to Stripe'} />}
      {showCancellationForm &&
        featureFlags.isOn(FEATURE_IDS.CANCELLATION_MODAL) && (
          <CancellationForm
            productType={customerProductType}
            subscriptionPeriodEnd={customerData?.customer.subscriptionPeriodEnd}
            open={showCancellationForm}
            onClose={closeCancellationForm}
            profileNames={profileNames}
            subscriptionStatus={subscriptionStatus || ''}
            billingSource={billingSource}
          />
        )}
      {showRenewalConfirmation && (
        <RenewalConfirmationDialog
          open={showRenewalConfirmation}
          onClose={() => setShowRenewalConfirmation(false)}
        />
      )}
    </>
  );
};

export default AccountSubscription;
