import AnalyticsService from '../../../analytics/Analytics.service';
import {BillingSource} from '../../../shared/types/customer';
import {isTrialing} from '../../../shared/utils/subscriptionStatus';
import {CancellationReasonsType, other} from './constants';
import {CancellationInput} from './types';

export function cancelSubscriptionWithMutation(
  cancelSubscription: (options: any) => Promise<any>,
) {
  return async (input: CancellationInput) => {
    try {
      const {data} = await cancelSubscription({
        variables: {
          input,
        },
      });
      return data;
    } catch (error) {
      console.log('cancel subscription:', error);
      return;
    }
  };
}

export function isRevenueCat(billingSource: string) {
  return billingSource === BillingSource.ReveneuCat;
}

export function isStripe(billingSource: string) {
  return billingSource === BillingSource.Stripe;
}

export function parseCancellationInput(
  choices: CancellationReasonsType,
  input: CancellationInput,
) {
  const {cancelCategory, cancelDetail, feedback} = input;
  const category = choices[cancelCategory]?.label || other;
  const detail =
    choices[cancelCategory]?.subchoices[cancelDetail]?.label || other;

  return {
    cancelCategory: category,
    cancelDetail: detail,
    feedback,
  };
}

export function trackCancellation(
  cancellationReasonChoices: CancellationReasonsType,
  cancellationDetails: CancellationInput,
  billingSource: string,
  subscriptionStatus: string,
): void {
  const isOnTrial = isTrialing(subscriptionStatus);

  const input = parseCancellationInput(
    cancellationReasonChoices,
    cancellationDetails,
  );

  const eventInput = {
    isOnTrial,
    cancelCategory: input.cancelCategory,
    cancelDetail: input.cancelDetail,
    feedback: input.feedback,
    subscriptionSource: billingSource,
  };

  AnalyticsService.trackCancellationReasonSubmitted(eventInput);
}
